const productCategories = [
    {
        name: "GMV Outdoor",
        category: "Outdoor",
    },
    {
        name: "GMV Indoor",
        category: "Indoor",
    },
    {
        name: "GMV AHU Kits & Mode Exch",
        category: "AHU Kits & Mode Exch",
    },
    {
        name: "GMV Controls",
        category: "Controls",
    },
    {
        name: "GMV Copper",
        category: "Copper",
    },
    {
        name: "R410a Mini Splits",
        category: "Mini Split",
    },
    {
        name: "R32 Mini Splits",
        category: "R32 Mini Split",
    }
]

const equipmentSummary = [
    {
        name: "Outdoor Equipment",
        category: "Outdoor",
        price: 0,
    },
    {
        name: "Indoor Equipment",
        category: "Indoor",
        price: 0,
    },
    {
        name: "AHU Kits & Mode Exch",
        category: "AHU Kits & Mode Exch",
        price: 0,
    },
    {
        name: "Controls",
        category: "Controls",
        price: 0,
    },
    {
        name: "Copper",
        category: "Copper",
        price: 0,
    },
    {
        name: "Mini Splits",
        category: "Mini Split",
        price: 0,
    },
    {
        name: "R32 Mini Splits",
        category: "R32 Mini Split",
        price: 0,
    }
]

const equipmentSummaryMap = {
    "Outdoor Equipment": "GMV Outdoor",
    "Indoor Equipment": "GMV Indoor",
    "AHU Kits & Mode Exch": "GMV AHU Kits & Mode Exch",
    "Controls": "GMV Controls",
    "Copper": "GMV Copper",
    "Mini Splits": "R410a Mini Splits",
    "R32 Mini Splits": "R32 Mini Splits",
}

export {
    productCategories,
    equipmentSummary,
    equipmentSummaryMap
}