<template>
  <v-container grid-list-xl>
    <!-- CUSTOMER INFO -->
    <v-dialog v-model="dialog.show" persistent :overlay="false" max-width="600px">
      <v-card>
        <v-card-title class="title primary white--text mb-4">Share Quote</v-card-title>
        <v-card-text>
          <p>
            Please select the team member, who you would like to share this quote with.
          </p>
          <v-autocomplete
            v-model="dialog.sharedWithEmail"
            :items="dialog.users"
            item-text="email"
            item-value="email"
            dense
            filled
            label="Team Member"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="
              dialog.show = false;
            "
            >Cancel</v-btn
          >
          <v-btn
            color="primary"
            @click="share"
            :loading="dialog.saving"
            :disabled="dialog.saving"
            >Share</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-card class="elevation-0">
          <v-card-title
            class="pa-0 ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-4">QUOTE</span>
            <v-btn class="ml-4" color="white" small dark outlined to="/quote"
              ><v-icon small class="mr-1">description</v-icon> create new</v-btn
            >
            <v-spacer></v-spacer>
            <v-img src="@/assets/img/greegmv5.png" max-width="150"></v-img>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="py-0" v-if="!loading">
                  <v-data-table
                    :headers="headers"
                    :items="quotes"
                    :search="search"
                  >
                    <template v-slot:top>
                      <v-text-field
                        v-model="search"
                        outlined
                        label="Filter by job name or customer"
                        dense
                      ></v-text-field>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title>Are you sure?</v-card-title>
                          <v-card-text>
                            Click on the delete button below to delete the quote
                            permanently.
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="dialogDelete = false"
                              >Cancel</v-btn
                            >
                            <v-btn color="primary" @click="confirmDelete"
                              >Delete</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </template>
                    <template v-slot:item.date="{ item }">
                      {{ item.job.date | moment("MM/DD/YY") }}
                    </template>
                    <template v-slot:item.expirationDate="{ item }">
                      {{ item.expirationDate ? new Date(item.expirationDate.seconds * 1000) : '' | asUtc | moment("MM/DD/YY") }}
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-btn
                        color="primary"
                        text
                        outlined
                        small
                        class="mr-2"
                        :to="`/quote/${item.id}`"
                        >EDIT</v-btn
                      >
                      <v-btn
                        class="mr-2"
                        color="primary"
                        text
                        outlined
                        small
                        @click="generatePDF(item)"
                        >PDF</v-btn
                      >
                      <v-btn
                        color="error"
                        text
                        outlined
                        small
                        @click="deleteQuote(item)"
                        ><v-icon small>delete</v-icon></v-btn
                      >
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn icon v-on="on" small class="ml-1">
                            <v-icon>mdi-dots-vertical</v-icon>
                          </v-btn>
                        </template>

                        <v-list dense>
                          <v-list-item
                            v-for="o in additionalOptions"
                            :key="o"
                            @click="option(o, item)"
                          >
                            <v-list-item-title>{{ o }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                    <template slot="no-data">
                      <span class="grey--text text--darken-3">
                        You have not created any quotes yet. Click the
                        <strong>"Create New"</strong>
                        button above.
                      </span>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import { mapGetters } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      dialogDelete: false,
      search: "",
      loading: true,
      dataExists: false,
      snack: false,
      snackColor: "",
      snackText: "",
      additionalOptions: ["Duplicate", "Share"],
      dialog: {
        show: false,
        saving: false,
        users: [],
        sharedWithEmail: ''
      },
      headers: [
        { text: "ID", value: "quoteReferenceNumber" },
        { text: "JOB NAME", value: "job.jobName" },
        { text: "CUSTOMER", value: "job.customer" },
        { text: "JOB DATE", value: "date" },
        { text: "PRICING EXP", value: "expirationDate" },
        { text: "SHARED BY", value: "sharedBy.email" },
        { text: "", value: "edit", align: "end", width: "260" },
      ],
      quotes: []
    };
  },
  async created() {
    this.getQuotes();
    this.getShareWithUsers();
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getQuotes() {
      const quotesRes = await fb.db
        .collection("quotes")
        .where("createdBy.uid", "==", this.user.uid)
        .get();
      const quotes = [];
      quotesRes.forEach((q) => {
        quotes.push({
          ...q.data(),
          id: q.id,
        });
      });
      this.quotes = quotes.sort((a, b) => (b.quoteReferenceNumber || 0) - (a.quoteReferenceNumber || 0));
      this.loading = false;
    },
    deleteQuote(quote) {
      this.currentQuote = {};
      this.currentQuote = { ...quote };
      this.dialogDelete = true;
    },
    async confirmDelete() {
      try {
        await fb.db.doc(`quotes/${this.currentQuote.id}`).delete();
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully deleted quote.";
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error deleting quote.";
      }
      this.dialogDelete = false;
      this.currentQuote = {};
      this.getQuotes();
    },
    generatePDF(quote) {
      let url;
      if (location.hostname === "localhost") {
        url = `http://localhost:5001/gree-commercial/us-central1/generatePdf?id=${quote.id}`;
      } else {
        url = `https://us-central1-gree-commercial.cloudfunctions.net/generatePdf?id=${quote.id}`;
      }
      window.open(url, "_blank");
    },
    async share() {
      if (this.dialog.sharedWithEmail) {
        this.dialog.saving = true;
        const quote = this.dialog.copy;
        const shareQuote = fb.functions.httpsCallable("shareQuote");
        await shareQuote({quote, sharedWithEmail: this.dialog.sharedWithEmail});
        this.dialog.saving = false;
        this.dialog.show = false;
        this.snack = true;
        this.snackColor = "success";
        this.snackText = "Successfully shared quote.";
      }
    },
    async getShareWithUsers() {
      const getUsers = fb.functions.httpsCallable("getCompanyUsers");
      const { data: users } = await getUsers();
      this.dialog.users = users.filter((u) => {return this.user.email != u.email;});
    },
    async option(option, item) {
      try {
        if (option === "Duplicate") {
          this.loading = true;
          const dupe = { ...item };
          dupe.job.jobName = "COPY " + (dupe.job.jobName || "");
          dupe.createDate = fb.timestamp;
          var getQuoteReferenceNumberCall = fb.functions.httpsCallable("getQuoteReferenceNumber");
          const quoteReferenceNumberRes = await getQuoteReferenceNumberCall();
          dupe.quoteReferenceNumber = quoteReferenceNumberRes.data;
          await fb.db.collection("quotes").add(dupe);
          this.getQuotes();
          this.loading = false;
        } else if (option === "Share") {
          this.dialog.show = true;
          this.dialog.copy = item;
        }
      } catch (error) {
        alert("Error duplicating job quote.");
        console.log(error);
        this.loading = false;
      }
    },
  },
};
</script>
