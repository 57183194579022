<template>
  <v-container grid-list-lg>
    <!-- CUSTOMER INFO -->
    <v-row>
      <v-col cols="12" sm="12" class="pb-0">
        <v-dialog v-model="dialogQuoteSubmitted" max-width="800" persistent>
          <v-card>
            <v-card-title primary-title>Quote Saved </v-card-title>
            <v-card-text class="text-center">
              <p>
                Your quote has been saved successfully.<br /><br />
                <v-btn color="primary" small outlined to="/"
                  >Back to home</v-btn
                >
              </p>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Equipment Modal -->
        <v-dialog
          v-model="dialogEquipment"
          transition="dialog-bottom-transition"
          overlay-opacity=".75"
          max-width="1400"
          width="98%"
          persistent
        >
          <Equipment @close="closeEquipmentDialog" />
        </v-dialog>
        <v-card class="elevation-0">
          <v-card-title
            class="pa-0 ma-0 primary white--text font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-4">QUOTE {{quoteReferenceNumber}}</span>
            <!-- <v-btn class="ml-4" color="white" small dark outlined @click="save"
              ><v-icon small class="mr-1">save</v-icon> save</v-btn
            > -->
            <v-spacer></v-spacer>
            <v-img src="@/assets/img/greegmv5.png" max-width="150"></v-img>
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-skeleton-loader type="table" v-if="loading"></v-skeleton-loader>
            <v-container fluid v-else>
              <v-row>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="name"
                    label="Job Name"
                    id="id"
                    v-model="job.jobName"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="job.date"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedDate"
                        label="Job Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="job.date" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(job.date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="name"
                    label="Customer"
                    v-model="job.customer"
                    id="id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-select
                    :items="['Design Build', 'Plan & Spec']"
                    v-model="job.jobType"
                    label="Job Type"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="name"
                    label="Salesman Information"
                    v-model="job.salesmanInfo"
                    id="id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-dialog
                    ref="estDialog"
                    v-model="estModal"
                    :return-value.sync="job.estDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formattedEstDate"
                        label="Est. Delivery Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="job.estDate" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="estModal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.estDialog.save(job.estDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-0 pt-3">
                  <h3>Job Address</h3>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="street1"
                    label="Street 1"
                    v-model="job.address.street1"
                    id="id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="street2"
                    label="Street 2"
                    v-model="job.address.street2"
                    id="id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <v-text-field
                    name="city"
                    label="City"
                    v-model="job.address.city"
                    id="id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <v-select
                    :items="states"
                    label="State"
                    name="state"
                    v-model="job.address.state"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <v-text-field
                    name="zip"
                    label="Zip"
                    v-model="job.address.zip"
                    id="id"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- LINE ITEMS -->
    <v-row>
      <v-col cols="12">
        <v-card class="mb-2 elevation-0" v-if="!loading">
          <v-card-title class="orange lighten-3 py-2">
            <h2 class="subtitle-1 font-weight-regular">EQUIPMENT</h2>
            <v-spacer></v-spacer>
            <v-btn small text outlined @click="dialogEquipment = true"
              ><v-icon x-small class="mr-1">edit</v-icon>EDIT</v-btn
            >
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left"></th>
                        <th class="text-center pr-0" width="75">Markup</th>
                        <th class="text-left" width="120">Price</th>
                        <th class="text-left" width="120">Final</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in equipmentSummary" :key="item.name">
                        <td>{{ overrideCategoryName(item.name) }}</td>
                        <td class="pr-0">
                          <v-text-field
                            class="markup"
                            dense
                            hide-details
                            value="0"
                            v-model.number="item.markup"
                          >
                            <v-icon class="mt-1" slot="append" small
                              >percent</v-icon
                            >
                          </v-text-field>
                        </td>
                        <td>{{ item.price | toCurrency }}</td>
                        <td>
                          {{
                            item.price +
                              item.price * ((item.markup || 0) / 100) ||
                            0 | toCurrency
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2"><strong>SUB-TOTAL</strong></td>
                        <td>
                          <strong>{{
                            equipmentSummarySubtotal | toCurrency
                          }}</strong>
                        </td>
                        <td>
                          <strong>{{
                            equipmentSummarySubtotalWithMarkup | toCurrency
                          }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-alert
          dense
          border="left"
          icon="error"
          type="error"
          v-show="discontinuedEquipmentCount"
        >
          {{discontinuedEquipmentCount}} model(s) in your quote are discontinued. Please edit equipment to current models.
        </v-alert>
        <v-card class="elevation-0" v-if="!loading">
          <v-card-title class="orange lighten-3 py-2">
            <h3 class="subtitle-1 font-weight-regular">ADDITIONAL ITEMS</h3>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Item</th>
                        <th class="text-left">Unit Price</th>
                        <th class="text-left">Qty</th>
                        <th class="text-left" width="140">Price</th>
                        <th class="text-center px-0" width="40"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, ix) in additionalItems" :key="ix">
                        <td>
                          <v-text-field
                            v-model="item.name"
                            placeholder="Item Name"
                            dense
                            hide-details
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model="item.price"
                            type="number"
                            prefix="$"
                            placeholder="Unit Price"
                            dense
                            hide-details
                          ></v-text-field>
                        </td>
                        <td>
                          <v-text-field
                            v-model.number="item.qty"
                            type="number"
                            min="0"
                            placeholder="Quantity"
                            dense
                            hide-details
                          ></v-text-field>
                        </td>
                        <td>{{ (item.qty * item.price) | toCurrency }}</td>
                        <td class="pa-0 text-center">
                          <v-btn
                            x-small
                            text
                            @click="additionalItems.splice(ix, 1)"
                            ><v-icon small>close</v-icon></v-btn
                          >
                        </td>
                      </tr>
                      <tr>
                        <td colspan="5">
                          <v-btn
                            outlined
                            block
                            text
                            small
                            style="border: 2px dashed; opacity: 0.4"
                            @click="newAddionalItem"
                            class="my-1"
                            ><v-icon small>add</v-icon> Add new line</v-btn
                          >
                        </td>
                      </tr>

                      <tr>
                        <td><strong>SUB-TOTAL</strong></td>
                        <td colspan="2"></td>
                        <td>
                          <strong>{{
                            additionalItemsSubTotal | toCurrency
                          }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2 elevation-0" v-if="!loading">
          <v-card-title>
            <h4 class="title">Additional Project Notes</h4>
          </v-card-title>
          <v-card-text>
            <v-textarea
              v-model="job.notes"
              label="Notes"
              outlined
              class="mt-2"
              hide-details
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <small class="mr-4" v-if="expirationDate">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    v-on="on"
                    small
                    class="ml-1"
                    style="cursor: pointer;"
                  >
                  mdi-help-circle
                  </v-icon>
                </template>
                <span>Latest pricing is applied & expiration is extended upon saving.</span>
              </v-tooltip>
              Pricing Expiration Date: <strong>{{ new Date(expirationDate.seconds * 1000) | asUtc | moment("MM/DD/YY") }}</strong>
            </small>
            <v-btn
              class="mr-2"
              color="primary"
              @click="save"
              :loading="saving"
              :disabled="saving"
            >
              <v-icon left class="mr-1">save</v-icon> save</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { sumBy } from "lodash";
import moment from "moment";
import Equipment from "../views/Equipment.vue";
import fb from "@/main";
import { mapGetters } from "vuex";
import { equipmentSummary, equipmentSummaryMap } from "@/helpers";

export default {
  name: "Home",
  components: {
    Equipment,
  },
  data() {
    return {
      dialogQuoteSubmitted: false,
      loading: true,
      dialogEquipment: false,
      modal: false,
      estModal: false,
      saving: false,
      snack: false,
      snackColor: "",
      snackText: "",
      job: null,
      expirationDate: null,
      headers: [
        {
          text: "",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Price", value: "price" },
      ],
      equipmentSummary: [...equipmentSummary],
      additionalItems: [],
      equipment: [],
      states: [
        "Alaska",
        "Alabama",
        "Arkansas",
        "American Samoa",
        "Arizona",
        "California",
        "Colorado",
        "Connecticut",
        "District of Columbia",
        "Delaware",
        "Florida",
        "Georgia",
        "Guam",
        "Hawaii",
        "Iowa",
        "Idaho",
        "Illinois",
        "Indiana",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Massachusetts",
        "Maryland",
        "Maine",
        "Michigan",
        "Minnesota",
        "Missouri",
        "Mississippi",
        "Montana",
        "North Carolina",
        "North Dakota",
        "Nebraska",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "Nevada",
        "New York",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Puerto Rico",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Virginia",
        "Virgin Islands",
        "Vermont",
        "Washington",
        "Wisconsin",
        "West Virginia",
        "Wyoming",
      ],
    };
  },
  async created() {
    this.job = {
      date: moment().format("YYYY-MM-DD"),
      estDate: null,
      jobName: "",
      customer: "",
      salesmanInfo: "",
      address: {
        street1: "",
        street2: "",
        city: "",
        state: "",
        zip: "",
      },
      notes: "",
      jobType: "Design Build",
      quoteReferenceNumber: ""
    };
    if (this.$route.name === "edit-quote") {
      var docRef = fb.db.collection("quotes").doc(this.$route.params.quoteId);
      const doc = await docRef.get();
      if (doc.exists) {
        const quote = doc.data();
        this.job = {
          ...quote.job,
        };
        this.additionalItems = quote.additionalItems;
        this.equipmentSummary = quote.equipmentSummary;
        this.quoteReferenceNumber = quote.quoteReferenceNumber;
        this.updateEquipmentSummary(quote.equipment);
        quote.equipmentSummary.forEach((es) => {
          if (es.markup) {
            this.equipmentSummary.find(
              (e) => e.category == es.category
            ).markup = es.markup;
          }
        });
        this.expirationDate = quote.expirationDate;
        await this.$store.dispatch("loadEquipment", quote.equipment);
        this.loading = false;
      } else {
        this.$router.push("/");
      }
    } else {
      await this.$store.dispatch("getEquipment");
      this.loading = false;
    }
  },
  computed: {
    formattedDate() {
      return this.job.date ? moment(this.job.date).format("MMMM Do YYYY") : "";
    },
    formattedEstDate() {
      return this.job.estDate
        ? moment(this.job.estDate).format("MMMM Do YYYY")
        : "";
    },
    additionalItemsSubTotal() {
      return sumBy(this.additionalItems, (ai) => ai.qty * ai.price).toFixed(2);
    },
    equipmentSummarySubtotal() {
      return sumBy(this.equipmentSummary, "price").toFixed(2);
    },
    equipmentSummarySubtotalWithMarkup() {
      return sumBy(this.equipmentSummary, (e) => {
        return e.price + e.price * ((e.markup || 0) / 100);
      }).toFixed(2);
    },
    discontinuedEquipmentCount() {
      const inactiveModels = this.$store.state.equipment.filter((eq) => eq.inactive).map((eq) => eq.model);
      return this.equipment.filter((eq) => {return inactiveModels.includes(eq.model)}).length;
    },
    ...mapGetters(["user"]),
  },
  methods: {
    overrideCategoryName(category) {
      return equipmentSummaryMap[category] || category;
    },
    newAddionalItem() {
      this.additionalItems.push({ name: "", qty: 1, price: 0 });
    },
    updateEquipmentSummary(e) {
      this.equipment = e;
      this.equipmentSummary.forEach((es) => {
        const items = e.filter((e) => e.category === es.category);
        es.price = parseFloat(
          sumBy(items, (eq) => eq.qty * eq.price).toFixed(2)
        );
      });
    },
    closeEquipmentDialog(e) {
      this.updateEquipmentSummary(e);
      this.dialogEquipment = false;
    },
    async save() {
      const editingQuote = this.$route.name === "edit-quote";
      this.equipmentSummary.forEach((es) => {
        es.finalPrice = es.price + es.price * ((es.markup || 0) / 100) || 0;
      });
      if (this.additionalItems.length) {
        this.additionalItems.forEach((item) => {
          item.finalPrice = (item.qty * item.price).toFixed(2);
        });
      }
      const quote = {
        job: this.job,
        additionalItems: this.additionalItems,
        equipment: this.equipment,
        equipmentSummary: this.equipmentSummary,
        equipmentSummarySubtotal: this.equipmentSummarySubtotal,
        equipmentSummarySubtotalWithMarkup:
          this.equipmentSummarySubtotalWithMarkup,
        additionalItemsSubTotal: this.additionalItemsSubTotal,
      };
      if (editingQuote) {
        quote.lastUpdated = fb.timestamp;
      } else {
        quote.createDate = fb.timestamp;
        quote.createdBy = this.user;
      }
      let expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 30);
      quote.expirationDate = expirationDate;
      this.expirationDate = {seconds: expirationDate.getTime()/1000}; // so UI updates
      this.saving = true;
      try {
        if (editingQuote) {
          await fb.db
            .collection("quotes")
            .doc(this.$route.params.quoteId)
            .update(quote);
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Successfully saved quote.";
        } else {
          var getQuoteReferenceNumberCall = fb.functions.httpsCallable("getQuoteReferenceNumber");
          const quoteReferenceNumberRes = await getQuoteReferenceNumberCall();
          quote.quoteReferenceNumber = quoteReferenceNumberRes.data;
          await fb.db.collection("quotes").add(quote);
          this.dialogQuoteSubmitted = true;
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Successfully saved quote.";
        }
      } catch (error) {
        this.snack = true;
        this.snackColor = "error";
        this.snackText = "Error saving quote.";
      }
      this.saving = false;
    },
  },
};
</script>
<style scoped>
.markup >>> .v-input__control {
  border: solid 1px #f2f2f2;
  border-radius: 3px;
  margin-bottom: 5px;
  padding-left: 2px;
  text-align: center;
  font-size: 0.875rem;
}
</style>
