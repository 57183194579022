var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',{staticClass:"px-0 mx-0 primary white--text font-weight-black headline",staticStyle:{"overflow":"hidden"}},[_c('span',{staticClass:"ml-4"},[_vm._v("Equipment")]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-4",attrs:{"color":"white","small":"","dark":"","outlined":""},on:{"click":_vm.closeEquipmentDialog}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("close")]),_vm._v(" close")],1)],1),_c('v-card-text',{staticClass:"pt-4 px-1"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.loading)?_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"table"}})],1):_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","md":"12"}},[_c('v-tabs',{attrs:{"active-class":"active-tab"}},_vm._l((_vm.categories),function(category){return _c('v-tab',{key:category,on:{"click":function($event){return _vm.categoryChange(category.category)}}},[_vm._v(_vm._s(category.name))])}),1),_c('v-divider'),_c('v-data-table',{attrs:{"dense":"","items":_vm.equipment,"loading":_vm.loading,"loading-text":"Loading....please wait.","headers":_vm.headersFiltered,"search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mt-3 mb-2",attrs:{"label":"Filter by product name or unit price","clearable":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.image)?_c('img',{attrs:{"src":item.image,"width":"30","height":"auto"}}):_vm._e(),(!item.image)?_c('img',{attrs:{"src":_vm.noImage,"width":"30","height":"auto"}}):_vm._e()]}},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"my-1",attrs:{"outlined":"","dense":"","min":"0","hide-details":"","type":"number"},model:{value:(item.qty),callback:function ($$v) {_vm.$set(item, "qty", _vm._n($$v))},expression:"item.qty"}})]}},{key:"item.model",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.model)+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.inactive)?_c('v-icon',_vm._g({staticClass:"ml-1",staticStyle:{"cursor":"pointer"},attrs:{"small":"","color":"error"}},on),[_vm._v(" mdi-alert ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Discontinued")])])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.price.toFixed(2))+" ")]}},{key:"item.subTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" $"+_vm._s(item.qty ? (Math.round(item.qty * item.price * 100) / 100).toFixed( 2 ) : 0)+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',[_c('th',{staticClass:"text-right",attrs:{"colspan":_vm.headersFiltered.length - 1}},[_vm._v(" SUB-TOTAL ")]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm._f("toCurrency")(_vm.subTotal)))])])])],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }