<template>
  <v-container grid-list-xl>
    <admin-navigation></admin-navigation>
    <br/>
    <v-row v-if="loading">
      <v-col cols="12" sm="12" md="8">
        <v-skeleton-loader type="card, card"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row v-if="!loading">
      <v-col cols="12">
        <v-dialog v-model="newCompanyDialog" width="500">
          <v-card>
            <v-form ref="newCompanyForm" lazy-validation>
              <v-card-title class="text-h5">{{newCompanyDialogEdit ? 'Edit' : 'Add'}} Client Company</v-card-title>
              <v-card-text>
                <v-text-field
                  name="name"
                  v-model="newCompany.name"
                  label="Company Name"
                  required
                  :rules="nameRules"
                  :disabled="newCompanyDialogEdit"
                ></v-text-field>
                <v-text-field
                  name="name"
                  v-model="newCompany.domain"
                  label="Company Email Domain"
                  required
                  :rules="domainRules"
                  :disabled="newCompanyDialogEdit"
                ></v-text-field>
                <v-select
                  :items="['All', 'GREE Commercial', 'MultiPro', 'DRS', 'CIAC']"
                  v-model="newCompany.type"
                  :rules="[requireCompanyCiacPairing, (v) => !!v || 'Type is required']"
                  label="Type"
                  required
                ></v-select>
                <v-checkbox
                  v-model="newCompany.zeroPricing"
                  label="Zero Pricing"
                ></v-checkbox>
                <v-select
                  :items="marginList"
                  v-model="newCompany.margin"
                  :rules="[(v) => !!v || 'Margin is required']"
                  item-text="name"
                  label="Margin"
                  required
                ></v-select>
                <v-select
                  :items="['DFS GOLD', 'DFS ELITE', 'DFS PLATINUM', 'DFS GC2', 'DFS CIAC', 'SUBS']"
                  v-model="newCompany.miniSplitTier"
                  :rules="[requireCompanyCiacPairing, (v) => !!v || 'Mini Split tier is required']"
                  label="Mini Split Pricing Tier"
                  required
                ></v-select>
                <v-text-field
                  name="name"
                  type="email"
                  v-model="newCompany.administratorEmail"
                  hint="This will be the assigned administrator who can remove access."
                  persistent-hint
                  label="Administrator Email"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancelCompany">Cancel</v-btn>
                <v-btn color="primary" @click="createNewCompany" :loading="loading" :disabled="loading">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-card class="elevation-0">
          <v-card-title
            class="ma-0 pa-4 orange lighten-3 font-weight-black headline"
            style="overflow: hidden"
          >
            <span class="ml-4" style="text-transform: uppercase;">Clients</span>
            <v-btn class="ml-4" small outlined @click="addCompany()"
              >New Client</v-btn
            >
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12" class="pa-0" v-if="loading">
                  <v-skeleton-loader type="table"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="12" class="pa-0" v-else>
                  <v-divider></v-divider>
                  <v-data-table
                    dense
                    :items="companies"
                    :loading="loading"
                    :headers="headersClients"
                    :items-per-page="200"
                  >
                    <template v-slot:item.name="{ item }"
                      >{{ item.name }}<br/>
                      <span class="caption grey--text">{{item.domain}}</span>
                    </template>
                    <template v-slot:item.margin="{ item }"
                      >{{ item.margin }}
                    </template>
                    <template v-slot:item.edit="{ item }"
                      ><v-btn x-small outlined text @click="editCompany(item)">edit</v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-if="!loading">
      <v-col cols="12">
        <v-dialog v-model="newUserTierDialog" width="500" class="mb-4">
          <v-card>
            <v-form ref="newUserForm" lazy-validation>
              <v-card-title class="text-h5">{{newUserTierDialogEdit ? 'Edit' : 'Add'}} User Tier</v-card-title>
              <v-card-text>
                <p>
                  Creating a user tier will supersede their company's tier.
                </p>
                <v-text-field
                  name="name"
                  v-model="newUserTier.email"
                  label="User Email"
                  required
                  :rules="[(v) => !!v || 'Email is required']"
                  :disabled="newUserTierDialogEdit"
                ></v-text-field>
                <v-select
                  :items="['All', 'GREE Commercial', 'MultiPro', 'DRS', 'CIAC']"
                  v-model="newUserTier.type"
                  :rules="[requireUserCiacPairing, (v) => !!v || 'Type is required']"
                  label="Type"
                  required
                ></v-select>
                <v-checkbox
                  v-model="newUserTier.zeroPricing"
                  label="Zero Pricing"
                ></v-checkbox>
                <v-select
                  :items="marginList"
                  v-model="newUserTier.margin"
                  :rules="[(v) => !!v || 'Margin is required']"
                  item-text="name"
                  label="Margin"
                  required
                ></v-select>
                <v-select
                  :items="['DFS GOLD', 'DFS ELITE', 'DFS PLATINUM', 'DFS GC2', 'DFS CIAC', 'SUBS']"
                  v-model="newUserTier.miniSplitTier"
                  :rules="[requireUserCiacPairing, (v) => !!v || 'Mini Split tier is required']"
                  label="Mini Split Pricing Tier"
                  required
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="cancelUserTier"> Cancel </v-btn>
                <v-btn color="primary" @click="createNewUserTier" :loading="loading" :disabled="loading">
                  Save
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
        <v-card class="elevation-0">
          <v-card-title
            class="px-0 mx-0 orange lighten-3 headline"
            style="overflow: hidden"
          >
            <span class="ml-4">User Tiers</span>
            <v-btn class="ml-4" small outlined @click="addUserTier()"
              >New User Tier</v-btn
            >
          </v-card-title>
          <v-card-text class="pt-4 px-1">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12" class="pa-0" v-if="loading">
                  <v-skeleton-loader type="table"></v-skeleton-loader>
                </v-col>
                <v-col cols="12" md="12" class="pa-0" v-else>
                  <v-divider></v-divider>
                  <v-data-table
                    dense
                    :items="userTiers"
                    :loading="loading"
                    :headers="headersUsers"
                    :items-per-page="200"
                  >
                    <template v-slot:item.margin="{ item }"
                      >{{ item.margin }}
                    </template>
                    <template v-slot:item.edit="{ item }"
                      >
                      <v-btn x-small outlined text @click="editUserTier(item)">edit</v-btn>
                      <v-btn x-small outlined text @click="deleteUserTier(item)" class="ml-1">delete</v-btn>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import AdminNavigation from "@/components/AdminNavigation.vue";

export default {
  components: {
    AdminNavigation
  },
  name: "Home",
  data() {
    return {
      nameRules: [(v) => !!v || "Name is required"],
      domainRules: [(v) => !!v || "Domain is required"],
      newCompanyDialog: false,
      newCompanyDialogEdit: false,
      newCompany: {},
      newUserTierDialog: false,
      newUserTierDialogEdit: false,
      newUserTier: {},
      userTiers: [],
      companies: [],
      marginList: [
        {
          name: "20%",
          value: 0.20,
        },
        {
          name: "23%",
          value: 0.23,
        },
        {
          name: "25%",
          value: 0.25,
        },
        {
          name: "28%",
          value: 0.28,
        },
        {
          name: "30%",
          value: 0.3,
        },
        {
          name: "33%",
          value: 0.33,
        },
        {
          name: "35%",
          value: 0.35,
        },
        {
          name: "38%",
          value: 0.38,
        },
        {
          name: "40%",
          value: 0.4,
        },
        {
          name: "43%",
          value: 0.43,
        },
      ],
      loading: true,
      headersClients: [
        {
          text: "Name",
          align: "start",
          value: "name",
          width: 200,
        },
        {
          text: "Type",
          sortable: false,
          value: "type",
        },
        {
          text: "Zero Pricing",
          sortable: false,
          value: "zeroPricing",
          width: 100,
        },
        {
          text: "Margin",
          sortable: false,
          value: "margin",
        },
        {
          text: "Mini Split Tier",
          sortable: false,
          value: "miniSplitTier",
        },
        {
          text: "Administrator",
          sortable: false,
          value: "administratorEmail",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "edit",
        },
      ],
      headersUsers: [
        {
          text: "Email",
          sortable: false,
          value: "email",
        },
        {
          text: "Type",
          sortable: false,
          value: "type",
        },
        {
          text: "Zero Pricing",
          sortable: false,
          value: "zeroPricing",
          width: 100,
        },
        {
          text: "Margin",
          sortable: false,
          value: "margin",
        },
        {
          text: "Mini Split Tier",
          sortable: false,
          value: "miniSplitTier",
        },
        {
          text: "",
          align: "end",
          sortable: false,
          value: "edit",
        },
      ],
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  async created() {
    this.getCompanies();
    this.getUserTiers();
  },
  methods: {
    requireCompanyCiacPairing() {
      if (this.newCompany.type === 'CIAC' && this.newCompany.miniSplitTier && this.newCompany.miniSplitTier !== 'DFS CIAC') {
        return 'Tier must be DFS CIAC to match type.';
      }
      if (this.newCompany.miniSplitTier === 'DFS CIAC' && this.newCompany.type && this.newCompany.type !== 'CIAC') {
        return 'Type must be CIAC to match tier.';
      }
      return true;
    },
    requireUserCiacPairing() {
      if (this.newUserTier.type === 'CIAC' && this.newUserTier.miniSplitTier && this.newUserTier.miniSplitTier !== 'DFS CIAC') {
        return 'Tier must be DFS CIAC to match type.';
      }
      if (this.newUserTier.miniSplitTier === 'DFS CIAC' && this.newUserTier.type && this.newUserTier.type !== 'CIAC') {
        return 'Type must be CIAC to match tier.';
      }
      return true;
    },
    async getCompanies() {
      try {
        this.loading = true;
        const companiesRes = await fb.db.collection("companies").get();
        const companies = [];
        companiesRes.forEach((doc) => {
          companies.push({
            ...doc.data(),
          });
        });
        this.companies = companies;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error.message);
        if (error.message === "Missing or insufficient permissions.") {
          this.$router.push("/");
        } else {
          alert("Error loading companies.");
        }
      }
    },
    createNewCompany() {
      if (this.$refs.newCompanyForm.validate()) {
        try {
          const domain = this.newCompany.domain.trim().toLowerCase();
          const freeEmailDomains = [
            "gmail.com", "yahoo.com", "outlook.com", "hotmail.com",
            "aol.com", "icloud.com", "live.com", "msn.com",
            "protonmail.com", "zoho.com", "comcast.net", "verizon.net",
            "att.net", "bellsouth.net", "charter.net", "cox.net",
            "earthlink.net", "juno.com", "mac.com", "mail.com",
            "me.com", "frontier.com", "roadrunner.com", "sbcglobal.net",
            "optimum.net", "windstream.net", "rr.com", "ymail.com",
            "tutanota.com", "gmx.com", "fastmail.com", "hushmail.com",
            "charter.net", "twc@.com"
          ];
          let validDomain = true;
          if (!domain.includes('.') || domain.includes('@')) {
            alert(`Invalid input: "${domain}". Please enter a valid domain (e.g., example.com).`);
            validDomain = false;
          }
          else if (freeEmailDomains.includes(domain)) {
            alert(`The domain "${domain}" is not allowed. Please use a company domain.`);
            validDomain = false;
          }

          if (validDomain) {
            this.loading = true;
            const administratorEmail = this.newCompany.administratorEmail
              .trim()
              .toLowerCase();
            fb.db.collection("companies").doc(domain).set({
              name: this.newCompany.name,
              domain: domain,
              type: this.newCompany.type,
              zeroPricing: !!this.newCompany.zeroPricing,
              margin: this.newCompany.margin,
              miniSplitTier: this.newCompany.miniSplitTier,
              administratorEmail,
            });
            this.newCompanyDialog = false;
            this.newCompanyDialogEdit = false;
            this.newCompany = {};
            this.getCompanies();
            this.loading = false;
          }
        } catch (error) {
          console.log(error);
          alert("Error creating company.");
        }
      } else {
        alert("Mssing information.");
      }
    },
    editCompany(company) {
      this.newCompany = { ...company };
      this.newCompanyDialog = true;
      this.newCompanyDialogEdit = true;
    },
    cancelCompany() {
      this.newCompanyDialog = false;
      this.newCompany = {};
    },
    addCompany() {
      this.newCompanyDialog = true;
      this.newCompanyDialogEdit = false;
    },
    async getUserTiers() {
      try {
        this.loading = true;
        const userTiersRes = await fb.db.collection("userTiers").get();
        const userTiers = [];
        userTiersRes.forEach((doc) => {
          userTiers.push({
            ...doc.data(),
          });
        });
        this.userTiers = userTiers;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error.message);
        if (error.message === "Missing or insufficient permissions.") {
          this.$router.push("/");
        } else {
          alert("Error loading user tiers.");
        }
      }
    },
    createNewUserTier() {
      if (this.$refs.newUserForm.validate()) {
        try {
          const email = this.newUserTier.email.trim().toLowerCase();
          fb.db.collection("userTiers").doc(email).set({
            email: this.newUserTier.email,
            type: this.newUserTier.type,
            zeroPricing: !!this.newUserTier.zeroPricing,
            margin: this.newUserTier.margin,
            miniSplitTier: this.newUserTier.miniSplitTier
          });
          this.newUserTierDialog = false;
          this.newUserTierDialogEdit = false;
          this.newUserTier = {};
          this.getUserTiers();
        } catch (error) {
          console.log(error);
          alert("Error creating user tier.");
        }
      } else {
        alert("Missing information.");
      }
    },
    editUserTier(user) {
      this.newUserTier = { ...user };
      this.newUserTierDialog = true;
      this.newUserTierDialogEdit = true;
    },
    cancelUserTier() {
      this.newUserTierDialog = false;
      this.newUserTier = {};
    },
    addUserTier() {
      this.newUserTierDialog = true;
      this.newUserTierDialogEdit = false;
    },
    async deleteUserTier(item) {
      if (confirm(`Are you sure you want to delete ${item.email}?`) == true) {
        try {
          await fb.db.doc(`userTiers/${item.email}`).delete();
          this.getUserTiers();
          this.snack = true;
          this.snackColor = "success";
          this.snackText = "Successfully deleted user tier.";
        } catch (error) {
          this.snack = true;
          this.snackColor = "error";
          this.snackText = "Error deleting user tier.";
        }
      }
    },
  },
};
</script>
