<template>
  <v-container grid-list-xl>
    <admin-navigation></admin-navigation>
    <br/>
    <v-card class="elevation-0">
      <v-card-title
        class="ma-0 pa-4 orange lighten-3 font-weight-black headline"
        style="overflow: hidden"
      >
        <span class="ml-4" style="text-transform: uppercase;">Equipment Pricing</span>
      </v-card-title>
      <v-card-text class="pt-4 px-1">
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="12" class="pa-0" v-if="loading">
              <v-skeleton-loader type="table"></v-skeleton-loader>
            </v-col>
            <v-col cols="12" md="12" class="pa-0" v-else>
              <v-tabs active-class="active-tab">
                <v-tab
                  v-for="category in categories"
                  :key="category"
                  @click="categoryChange(category.category)"
                  >{{ category.name }}</v-tab
                >
              </v-tabs>
              <v-divider></v-divider>
              <v-data-table
                dense
                :items="filteredEquipment"
                :loading="loading"
                loading-text="Loading....please wait."
                :headers="headers"
                :search="search"
              >
                <template v-slot:top>
                  <v-text-field
                    v-model="search"
                    label="Filter by product name or unit price"
                    clearable
                    outlined
                    dense
                    hide-details
                    class="mt-3 mb-2"
                  ></v-text-field>
                </template>
                <template v-slot:item.cost="{ item }">
                  <v-text-field
                    class="my-1"
                    outlined
                    dense
                    hide-details
                    v-model.number="item.cost"
                    type="number"
                    :disabled="['Mini Split', 'R32 Mini Split'].includes(item.category) || true"
                  ></v-text-field>
                </template>
                <template v-slot:item.save="{ item }">
                  <v-btn
                    style="display: none;"
                    color="primary"
                    outlined
                    text
                    @click="updatePrice(item)"
                    >save</v-btn
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import fb from "@/main";
import AdminNavigation from "@/components/AdminNavigation.vue";
import { productCategories } from "@/helpers";

export default {
  components: {
    AdminNavigation
  },
  name: "Home",
  data() {
    return {
      loading: true,
      equipment: [],
      category: "Outdoor",
      search: "",
      snack: false,
      snackColor: "",
      snackText: "",
      categories: [...productCategories],
      headers: [
        {
          text: "Model",
          value: "model",
        },
        {
          text: "Description",
          sortable: false,
          value: "description",
        },
        { text: "Unit Price", value: "cost", align: "center", width: "140" },
        { text: "", value: "save", align: "end", width: "100" },
      ],
    };
  },
  async created() {
    try {
      this.unsubscribeDemobs = await fb.db
        .collection("equipment")
        .onSnapshot((querySnapshot) => {
          let equipment = [];
          querySnapshot.forEach(function (doc) {
            equipment.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.equipment = equipment;
        });
      this.loading = false;
    } catch (error) {
      this.loading = false;
      if (error.message === "Missing or insufficient permissions.") {
        this.$router.push("/");
      } else {
        alert("Error loading companies.");
      }
    }
  },
  computed: {
    filteredEquipment() {
      return this.equipment.filter((e) => e.category == this.category);
    },
  },
  methods: {
    async updatePrice(item) {
      await fb.db.collection("equipment").doc(item.id).update({
        cost: item.cost,
      });
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Successfully saved price.";
    },
    categoryChange(t) {
      this.category = t;
    },
  },
};
</script>
<style>
tbody tr:hover {
  background-color: transparent !important;
}
</style>
